import React, { useEffect } from "react"

import Layout from "../components/layout"

interface ClientDownloadsProps {
  url: string
  clientName?: string
  files: {
    link_title: { text: string }
    file: { url: string }
  }[]
}

const ClientDownloadsPage = props => {
  const pageProps: ClientDownloadsProps = props.pageContext
  const { url, files, clientName } = pageProps

  console.log(url, files)

  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: url,
        page_path: "/" + url,
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])

  return (
    <>
      <Layout>
        <div className="absolute inset-0 bg-blue-300 -z-10"></div>
        <div className="flex flex-col items-center bg-blue-300">
          <div className="flex items-center justify-center w-32 h-32 mt-6 text-2xl font-semibold text-white bg-black rounded-full sm:w-40 sm:h-40 lg:w-48 lg:h-48 lg:text-3xl">
            RADDAR
          </div>
          <div className="mt-4 text-2xl">{clientName}</div>
          <div className="w-10/12 sm:w-80">
            {files.map(({ link_title, file }) => {
              return (
                <a
                  href={file.url}
                  target="_blank"
                  className="block w-full py-1 my-6 font-medium text-center text-white no-underline bg-black rounded-sm md:my-8"
                >
                  {link_title.text}
                </a>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ClientDownloadsPage
